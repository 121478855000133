<template>
  <div class="KCmodule">
    <Header title="工程专利" index="首页" titleOne="科创模块" beforeTitle="工程专利" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-select
            clearable
            v-model="patprojectParams.patent_type"
            placeholder="请选择专利类型"
            @change="changePatentType"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="发明专利" value="1">发明专利</el-option>
            <el-option label="实用新型" value="2">实用新型</el-option>
            <el-option label="外观设计" value="3">外观设计</el-option>
            <el-option label="植物新品种" value="4">植物新品种</el-option>
            <el-option label="国家新药" value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.status"
            placeholder="请选择专利状态"
            @change="changeStatus"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="新申请" value="1">新申请</el-option>
            <el-option label="审查中" value="2">审查中</el-option>
            <el-option label="审查合格" value="3">审查合格</el-option>
            <el-option label="发明实审" value="4">发明实审</el-option>
            <el-option label="授权" value="5">授权</el-option>
            <el-option label="驳回" value="6">驳回</el-option>
            <el-option label="撤回" value="7">撤回</el-option>
            <el-option label="专利权维持" value="8">专利权维持</el-option>
            <el-option label="专利权失效" value="9">专利权失效</el-option>
          </el-select>
          <el-input clearable
            v-model="patprojectParams.company_name"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input clearable
            v-model="patprojectParams.patent_name"
            placeholder="请输入专利号/专利名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
          <el-button
            v-if="privilige_list.engineering.includes('upload')"
            class="upload"
            @click="quick()"
          >快捷上传</el-button>
          <el-button
            disabled
            v-if="privilige_list.engineering.includes('export')"
            @click="exportAll()"
            class="import"
          >全部导出</el-button>
        </div>
        <el-button
          v-if="privilige_list.engineering.includes('add')"
          class="add"
          @click="addPatent('')"
        >新增</el-button>
        <div class="clear" style="margin-top: 20px">
          <el-select clearable v-model="patprojectParams.province_id" placeholder="请选择省" @change="getcity">
            <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select clearable v-model="patprojectParams.city_id" placeholder="请选择市" @change="gettown">
            <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select clearable v-model="patprojectParams.town_id" placeholder="请选择区">
            <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-date-picker
            v-model="patprojectParams.app_date"
            type="daterange"
            range-separator="至"
            start-placeholder="申请开始日期"
            end-placeholder="申请结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-date-picker
            v-model="patprojectParams.certificate_date"
            type="daterange"
            range-separator="至"
            start-placeholder="下证开始日期"
            end-placeholder="下证结束日期"
            @change="chooseTimeRange2"
          ></el-date-picker>
        </div>
      </div>
      <!-- <keep-alive> -->
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="patproject.data"
        style="width: 100%"
      >
        <!-- v-if="checkList.includes('企业名称')" -->
        <el-table-column
          v-if="headerStatus.company_name_status"
          prop="company_name"
          label="企业名称"
          :show-overflow-tooltip="true"
          fixed
          width="150"
        >
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.area_status"
          prop="area"
          label="所属区域"
          width="150"
          :show-overflow-tooltip="true"
          fixed
        >
          <template slot-scope="scope">
            {{
            scope.row.area ? scope.row.area : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.business_type_status"
          prop="business_type"
          label="业务类型"
          width="100"
          :show-overflow-tooltip="true"
          fixed
        >
          <template slot-scope="scope">
            {{
            scope.row.business_type ? scope.row.business_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.patent_name_status"
          prop="patent_name"
          label="专利名称"
          :show-overflow-tooltip="true"
          fixed
          width="150"
        >
          <template slot-scope="scope">
            {{
            scope.row.patent_name ? scope.row.patent_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.patent_type_internal_status"
          prop="patent_type_internal"
          label="专利类型(内部)"
          width="130"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.patent_type_internal ? scope.row.patent_type_internal : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.internal_code_status"
          prop="internal_code"
          label="内部编号"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.internal_code ? scope.row.internal_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.app_date_status"
          prop="app_date"
          label="申请日期"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.app_date ? scope.row.app_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.app_code_status"
          prop="app_code"
          label="申请号"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.app_code ? scope.row.app_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.custom_manager_status"
          prop="custom_manager"
          label="客户经理"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.custom_manager ? scope.row.custom_manager : "-"
            }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="engineer" label="工程师" width="150" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{
              scope.row.engineer ? scope.row.engineer : "-"
              }} 
            </template>
          </el-table-column>
          <el-table-column prop="process" label="流程" width="150" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              {{
              scope.row.process ? scope.row.process : "-"
              }}
            </template>
        </el-table-column>-->
        <el-table-column
          v-if="headerStatus.app_fee_status"
          prop="app_fee"
          label="申请费"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.app_fee ? scope.row.app_fee : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.publish_fee_status"
          prop="publish_fee"
          label="公布费"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.publish_fee ? scope.row.publish_fee : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.examination_fee_status"
          prop="examination_fee"
          label="实审费"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.examination_fee ? scope.row.examination_fee : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.authorize_fee_status"
          prop="authorize_fee"
          label="授权费"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.authorize_fee ? scope.row.authorize_fee : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.other_fee_status"
          prop="other_fee"
          label="其他费"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.other_fee ? scope.row.other_fee : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.patent_status_internal_status"
          prop="patent_status_internal"
          label="专利状态(内部)"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.patent_status_internal ? scope.row.patent_status_internal : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.certificate_date_status"
          prop="certificate_date"
          label="下证情况"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.certificate_date ? scope.row.certificate_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.certificate_situation_status"
          prop="certificate_situation"
          label="证书分发情况"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.certificate_situation ? scope.row.certificate_situation : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.subsidy_status"
          prop="subsidy"
          label="专利补助年费"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{
            scope.row.subsidy ? scope.row.subsidy : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.annual_fee_status"
          prop="annual_fee"
          label="年费"
          width="90"
        >
          <template slot-scope="scope">
            <el-tooltip placement="top">
              <div v-html="ToBreak(scope.row.annual_fee_str)" slot="content"></div>
              <div class="oneLine">{{scope.row.annual_fee ? scope.row.annual_fee : "-"}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="headerStatus.pay_status_status"
          prop="pay_status"
          label="缴费"    
          fixed="right"
          id="status"
        >
          <template slot-scope="scope">
            <span class="statusbtn" style="margin-left: " v-if="scope.row.pay_status == 0">-</span>
            <el-tooltip
              :content="`请在 ${scope.row.last_pay_time}前，进行缴费！`"
              placement="bottom"
              effect="light"
            >
              <span
                class="statusbtn"
                style="color: #ffbe2c; margin-left: "
                v-if="scope.row.pay_status == 1"
              >待缴</span>
            </el-tooltip>

            <el-tooltip content="已过期，请尽快缴费！" placement="bottom" effect="light">
              <span
                style="color: #ff5865; margin-left: "
                class="statusbtn"
                v-if="scope.row.pay_status == 2"
              >滞纳</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column prop="operation" label="操作" width="270px" fixed="right">
          <template slot="header">
            <div class="table_header">
            <span>操作</span>
            <!-- <el-button type="primary" plain size="small">编辑表头</el-button> -->
            <img  @click="headerEdit" src="../../assets/images/patent_table_header.png" alt="">
            </div>
          </template>
          <template slot-scope="scope">
            <el-button
              v-if="privilige_list.engineering.includes('edit')"
              class="edit"
              size="mini"
              @click="addPatent(scope.row.id)"
            >编辑</el-button>
            <el-button
              v-if="privilige_list.engineering.includes('file')"
              class="edit"
              size="mini"
              @click="UploadFile(scope.row.id)"
            >附件</el-button>
            <el-button
              class="edit"
              size="mini"
              @click="detailPatent(scope.row.id)"
            >详情</el-button>
            <el-button
              v-if="privilige_list.engineering.includes('delete')"
              class="del"
              size="mini"
              @click="handleDelete(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="表头编辑" :visible.sync="dialogTableVisible" width="300px">
        <el-checkbox-group>
          <el-checkbox label="企业名称" v-model="headerStatus.company_name_status" :checked="headerStatus.company_name_status"></el-checkbox>
          <el-checkbox label="所属区域" v-model="headerStatus.area_status" :checked="headerStatus.area_status"></el-checkbox>
          <el-checkbox label="业务类型" v-model="headerStatus.business_type_status"  :checked="headerStatus.business_type_status"></el-checkbox>
          <el-checkbox label="专利名称" v-model="headerStatus.patent_name_status"  :checked="headerStatus.patent_name_status"></el-checkbox>
          <el-checkbox label="专利类型（内部）" v-model="headerStatus.patent_type_internal_status"  :checked="headerStatus.patent_type_internal_status"></el-checkbox>
          <el-checkbox label="内部编号" v-model="headerStatus.internal_code_status"  :checked="headerStatus.internal_code_status"></el-checkbox>
          <el-checkbox label="申请日期"  v-model="headerStatus.app_date_status" :checked="headerStatus.app_date_status"></el-checkbox>
          <el-checkbox label="申请号" v-model="headerStatus.app_code_status"  :checked="headerStatus.app_code_status"></el-checkbox>
          <el-checkbox label="客户经理" v-model="headerStatus.custom_manager_status"  :checked="headerStatus.custom_manager_status"></el-checkbox>
          <el-checkbox label="申请费" v-model="headerStatus.app_fee_status"  :checked="headerStatus.app_fee_status"></el-checkbox>
          <el-checkbox label="公布费" v-model="headerStatus.publish_fee_status"  :checked="headerStatus.publish_fee_status"></el-checkbox>
          <el-checkbox label="实审费" v-model="headerStatus.examination_fee_status"  :checked="headerStatus.examination_fee_status"></el-checkbox>
          <el-checkbox label="授权费"  v-model="headerStatus.authorize_fee_status" :checked="headerStatus.authorize_fee_status"></el-checkbox>
          <el-checkbox label="其他费" v-model="headerStatus.other_fee_status"  :checked="headerStatus.other_fee_status"></el-checkbox>
          <el-checkbox label="专利状态（内部）"  v-model="headerStatus.patent_status_internal_status" :checked="headerStatus.patent_status_internal_status"></el-checkbox>
          <el-checkbox label="下证情况" v-model="headerStatus.certificate_date_status"  :checked="headerStatus.certificate_date_status"></el-checkbox>
          <el-checkbox label="证书分发情况" v-model="headerStatus.certificate_situation_status"  :checked="headerStatus.certificate_situation_status"></el-checkbox>
          <el-checkbox label="专利补助年费"  v-model="headerStatus.subsidy_status" :checked="headerStatus.subsidy_status"></el-checkbox>
          <el-checkbox label="年费"  v-model="headerStatus.annual_fee_status" :checked="headerStatus.annual_fee_status"></el-checkbox>
          <el-checkbox label="缴费" v-model="headerStatus.pay_status_status"  :checked="headerStatus.pay_status_status"></el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" @click="headerSure()">确 定</el-button>
        </div>
      </el-dialog>
      <!-- </keep-alive> -->
      <div class="block" v-if="patproject.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="patprojectParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="patprojectParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="patproject.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      headerStatus: {
        company_name_status: true,//企业名
        area_status: true,//所属区域
        business_type_status: true,//业务类型
        patent_name_status: true,//专利名称
        patent_type_internal_status: true,//专利类型（内部）
        internal_code_status: true,//内部编号
        app_date_status: true,//申请日期
        app_code_status: true,//申请号
        custom_manager_status: true,//客户经理
        app_fee_status: true,//申请费
        publish_fee_status: true,//公布费
        examination_fee_status: true,//实审费
        authorize_fee_status: true,//授权费
        other_fee_status: true,//其他费
        patent_status_internal_status: true,//专利状态（内部）
        certificate_date_status: true,//下证情况
        certificate_situation_status: true,//证书分发情况
        subsidy_status: true,
        annual_fee_status: true,
        pay_status_status: true,
      },
      checkList: [],
      dialogTableVisible: false,
      opt: { id: '0', name: '全部' },
      pay_status_list: [
        {
          status: 0,
          value: "-",
          color: "",
        },
        {
          status: 1,
          value: "待缴",
          color: "#FFBE2C",
        },
        {
          status: 2,
          value: "滞纳",
          color: "#FF5865",
        },
      ],
      loading: true,
      province: [],
      city: [],
      town: [],
      patprojectTotal: 0,
      patprojectParams: {
        page: 1,
        limit: 10,
        keywords: "",
        patent_name: "",
        patent_type: "",
        status: "",
        company_name: "",
        province_id: "",
        city_id: "",
        town_id: "",
        order: "id desc",
        // app_date: null,
        // certificate_date: null,
      },
      privilige_list: {},
      patproject:[],
    };
  },
  activated () {
    // this.handleSearch();
    this.patprojectParams
    this.$store.dispatch("getpatproList", this.patprojectParams);
  },
  // computed: {
  //   ...mapState(["patproject"]),
  // },
  created () {
    this.getList()
    this.getprovince();
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    let status = localStorage.getItem('patent_header_status')
    if(JSON.parse(status)){
      this.headerStatus = JSON.parse(status)
    }
  },
  methods: {
    getList(){
      this.axios.get('/api/patent/project_patent_list',this.patprojectParams).then((res)=>{
        this.patproject = res.data
        setTimeout(()=>{
          this.loading = false
        },100)
      })
    },
    detailPatent(id){
      this.$router.push({path:'/patent/project_detail',query:{id:id}})
    },
    headerSure () {
      localStorage.setItem('patent_header_status',JSON.stringify(this.headerStatus))
      this.dialogTableVisible = false
      this.$message.success('保存成功')
    },
    headerEdit (column) {
      this.dialogTableVisible = true
    },
    exportAll () {
      // this.$router.push('')
    },
    ToBreak (val) {
      if (val == undefined) {
        return val;
      } else {
        return val.split("\\n").join("<br/>");
      }
    },
    chooseTimeRange1 (t) {
      console.log(t);
            this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
    },
    chooseTimeRange2 (t) {
            this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
      this.$store.dispatch("getpatproList", this.patprojectParams);
    },
    addPatent (id) {
      this.$router.push("/patent/zladd?id=" + id);
    },
    quick () {
      this.$router.push("/patent/quick");
    },
    UploadFile (id) {
      this.$router.push("/patent/annex?id=" + id);
    },
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
        this.province.unshift(this.opt)
        // this.loading = false;
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
                this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
        });
      if (this.patprojectParams.province_id == "0") {
        this.patprojectParams.city_id = "";
        this.patprojectParams.town_id = "";
      }
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
        this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
        });
    },
    handleSizeChange (val) {
      this.patprojectParams.limit = val;
      this.loading = true 
      // this.$store.dispatch("getpatproList", this.patprojectParams);
      this.axios.get('/api/patent/project_patent_list',{params:{...this.patprojectParams}}).then((res)=>{
        this.patproject = res.data
        setTimeout(()=>{
          this.loading = false
        },100)
      })
    },
    handleCurrentChange (val) {
      this.patprojectParams.page = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, keywords: '' });
      this.axios.get('/api/patent/project_patent_list',{params:{...this.patprojectParams,keywords:''}}).then((res)=>{
        this.patproject = res.data
        setTimeout(()=>{
          this.loading = false
        },100)
      })
    },
    changePatentType () {      
      this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
    },
    changeStatus () {      
      this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
    },
    handleSearch () {
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, page: '', limit: '' });
      this.loading = true
      this.axios.get('/api/patent/project_patent_list',{params: { ...this.patprojectParams, page: ''}}).then((res)=>{
        this.patproject = res.data
        this.loading = false
      })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/patent/delete", {
            params: {
              id: id,
            },
          });
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.reload();
          this.$store.dispatch("getReportData", this.reportParams);
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped="scoped">
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.KCmodule .import {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}

.KCmodule .upload {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}

::v-deep .el-tabs__content {
  top: 20px !important;
}

::v-deep .topUsers .el-input {
  width: 224px !important;
}

.el-date-editor--daterange.el-input__inner {
  width: 270px;
  margin-right: 20px;
}
div/deep/.el-table_1_column_22 {
  padding-left: 20px !important;
}
div/deep/.el-table__body-wrapper {
  overflow-x: scroll !important;
}
div/deep/.el-table__body {
  margin-bottom: 10px;
}
.el-checkbox {
  display: block;
  margin-top: 10px;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
.table_header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table_header img{
  width: 20px;
  cursor: pointer;
  margin-right: 40px;
}
</style>
